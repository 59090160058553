import Button from '@/components/button/Button';
import { ConfigContext } from '@/context/ConfigContext';
import { Feedback } from '@/general/model/feedback';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import useSWR from 'swr';
import FeedbackStatusOverview from './FeedbackStatusOverview';
import InviteFeedbackProvidersModal from './InviteFeedbackProvidersModal';
import useCurrentAccount from '@/hooks/useCurrentAccount';
import { PERMISSIONS } from '@/general/constants';
import './ProfileEvaluation.scss';

function ProfileEvaluation() {
    const configContext = useContext(ConfigContext);
    const endpoint =
        configContext.configBody.api.baseUrl +
        configContext.configBody.api.endpoints.feedbacks.list;
    const { data: feedbacks, isLoading, mutate } = useSWR<Feedback[]>(endpoint);

    const [showFeedbackProvidersModal, setShowFeedbackProvidersModal] = useState(false);

    // Permissions check
    const { hasPermission } = useCurrentAccount();
    const canAccessFeedback = hasPermission(PERMISSIONS.FEEDBACK_VIEW); // Replace with the actual permission key
    const canInviteFeedbackProviders = hasPermission(PERMISSIONS.FEEDBACK_INVITE); // Replace with the actual permission key

    return (
        <div className='profile-evaluation'>
            <Row>
                <Col sm={12} className='feedback'>
                    <h3 className='title'>{t('development:evaluation:feedbackTitle')}</h3>
                    <p className='description'>{t('development:evaluation:feedbackDescription')}</p>
                    <Button
                        text={t('development:evaluation:feedbackBtn')}
                        displayType='secondary'
                        icon={canInviteFeedbackProviders ? 'user-check' : 'lock'}
                        onClick={() => {
                            if (canInviteFeedbackProviders) {
                                setShowFeedbackProvidersModal(true);
                            }
                        }}
                        disabled={!canInviteFeedbackProviders}
                    />

                    {!isLoading && feedbacks?.length > 0 && canAccessFeedback ? (
                        <FeedbackStatusOverview feedbacks={feedbacks} onSuccess={() => mutate()} />
                    ) : (
                        !isLoading &&
                        !canAccessFeedback && (
                            <div className='no-permission-message mt-2'>
                                {t('development:evaluation:noPermissionFeedback')}
                            </div>
                        )
                    )}
                </Col>
            </Row>

            {canInviteFeedbackProviders && (
                <InviteFeedbackProvidersModal
                    showModal={showFeedbackProvidersModal}
                    onClose={() => setShowFeedbackProvidersModal(false)}
                    onSuccess={() => {
                        setShowFeedbackProvidersModal(false);
                        mutate();
                    }}
                />
            )}
        </div>
    );
}

export default ProfileEvaluation;
