import { FC } from "react";
import { ProfileStepProps } from "@/components/onboarding/ProfileStepProps";
import { Col, Form, Row } from "react-bootstrap";
import { t } from "i18next";
import "./ProfileStep.scss";

/**
 * Displays and validates user profile information (first name, surname, city, etc.).
 */
const ProfileStep: FC<ProfileStepProps> = ({
                                               formRef,
                                               validated,
                                               givenName,
                                               surname,
                                               city,
                                               nationality,
                                               onUpdateTempChanges,
                                           }) => {
    return (
        <section className="profile-step">
            <h2>{t('profileStep:title')}</h2>
            <p>{t('profileStep:description')}</p>

            <Form ref={formRef} validated={validated} noValidate>
                {/* First Name and Last Name in the Same Row */}
                <Form.Group as={Row} className="mt-3">
                    <Col md={6}>
                        <Form.Label>
                            <span>{t("profile:personalData:labelGivenName")}</span>
                            <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            required
                            autoComplete="given-name"
                            value={givenName}
                            onChange={(e) => onUpdateTempChanges("givenName", e.target.value)}
                        />
                    </Col>
                    <Col md={6}>
                        <Form.Label>
                            <span>{t("profile:personalData:labelSurname")}</span>
                            <span className="required">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            required
                            autoComplete="family-name"
                            value={surname}
                            onChange={(e) => onUpdateTempChanges("surname", e.target.value)}
                        />
                    </Col>
                </Form.Group>

                {/* City */}
                <Form.Group as={Row} className="mt-3">
                    <Col>
                        <Form.Label>{t("profile:personalData:labelCity")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            autoComplete="address-level2"
                            value={city}
                            onChange={(e) => onUpdateTempChanges("city", e.target.value)}
                        />
                    </Col>
                </Form.Group>

                {/* Nationality */}
                <Form.Group as={Row} className="mt-3">
                    <Col>
                        <Form.Label>{t("profileStep:nationalityLabel")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder=""
                            value={nationality}
                            onChange={(e) => onUpdateTempChanges("nationality", e.target.value)}
                        />
                    </Col>
                </Form.Group>
            </Form>
        </section>
    );
};

export default ProfileStep;
