import { useEffect, useState } from 'react';
import QuestionList from '../video-recorder/QuestionList';
import VideoRecorder from '../video-recorder/VideoRecorder';
import { t } from "i18next";
import './OnboardingPitch.scss';
import Button from '../button/Button';
import LinkButton from '../button/LinkButton';

function OnboardingPitch() {

    // TODO: dev purposes, remove later
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);

    const [videoProcessed, setVideoProcessed] = useState(true);

    function onRecordingSubmit(blob: Blob) {
        setLoading(true);
        setTimeout(() => {
            setVideoProcessed(true);
            setLoading(false);
        }, 5000);
    }

    const questions: string[] = [
        "Wie ben je?",
        "Waar woon je?",
        "Ben je momenteel werkzaam?",
        "Wat is je ervaring?",
        "Wat zijn je ambities?"
    ];

    return (
        <div className='onboarding-pitch'>
            {!videoProcessed &&
                <>
                    {loading &&
                        <div className='loading'>
                            <h1 className='title'>{t('onboarding:videoProcessingTitle')}</h1>
                            <p className='description'>{t('onboarding:videoProcessingDescription')}</p>
                            <div className="loading-bar-container">
                                <div className="loading-bar" />
                            </div>
                        </div>
                    }
                    {error &&
                        <div className='error'>
                            <h1 className='title'>Er is helaas iets mis gegaan.</h1>
                            <p className='description'>{error}</p>
                            <Button text='Probeer opnieuw' icon='rotate-right' onClick={() => { setVideoProcessed(false); setError(null) }} />
                        </div>
                    }
                    {!loading && !error &&
                        <div className='pitch-wrapper'>
                            <VideoRecorder submitBtnText='Analyseer video' onRecordingSubmit={(blob) => { onRecordingSubmit(blob) }} />
                            <QuestionList title='Stel jezelf voor' questions={questions} />
                        </div>
                    }
                </>
            }
            {videoProcessed &&
                <div className='pitch-processed'>
                    <h1 className='title'>{t('onboarding:pitchTitle')}</h1>
                    <p className='description'>{t('onboarding:pitchDescription')}</p>
                    <LinkButton text={t('onboarding:toProfileButton')} icon='user' to={'/profile'}/>
                </div>
            }
        </div>
    )
}

export default OnboardingPitch