import { t } from "i18next"
import './VouchersFrequentlyAskedQuestions.scss'
import { Col, Row } from "react-bootstrap"

function VouchersFrequentlyAskedQuestions() {
    return (
        <div className="vouchers-faq">
            <section className="header">
                <h2 className="title">{t('vouchers:faq:title')}</h2>
                <p className="help">
                    {t('vouchers:faq:missingQuestion')}
                    <a href={t('vouchers:faq:linkUrl')} className="ms-2">
                        {t('vouchers:faq:link')}
                    </a>
                </p>
            </section>
            <Row className="faq g-4">
                <Col sm={12} md={6} className="faq-item">
                    <i className="icon fas fa-ticket"></i>
                    <h3 className="question">{t('vouchers:faq:question1')}</h3>
                    <p className="answer">{t('vouchers:faq:answer1')}</p>
                </Col>
                <Col sm={12} md={6} className="faq-item">
                    <i className="icon fas fa-file-import"></i>
                    <h3 className="question">{t('vouchers:faq:question2')}</h3>
                    <p className="answer">{t('vouchers:faq:answer2')}</p>
                </Col>
                <Col sm={12} md={6} className="faq-item">
                    <i className="icon fas fa-clock"></i>
                    <h3 className="question">{t('vouchers:faq:question3')}</h3>
                    <p className="answer">{t('vouchers:faq:answer3')}</p>
                </Col>
                <Col sm={12} md={6} className="faq-item">
                    <i className="icon fas fa-copy"></i>
                    <h3 className="question">{t('vouchers:faq:question4')}</h3>
                    <p className="answer">{t('vouchers:faq:answer4')}</p>
                </Col>
            </Row>
        </div>
    )
}

export default VouchersFrequentlyAskedQuestions