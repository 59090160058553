import LinkButton from "@/components/button/LinkButton";
import SwiperLockedComponent from "@/components/swiper-locked-component/SwiperLockedComponent";
import { PERMISSIONS } from "@/general/constants";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import useRecommendedProfessions from "@/hooks/useRecommendedProfessions";
import { t } from "i18next";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProfessionSwiper from "./ProfessionSwiper";

function RecommendedProfessions() {
    const { totalCompletion } = useProfileCompletionRequirements();
    const { recommendedProfessions, mutate } = useRecommendedProfessions();
    const { hasPermission } = useCurrentAccount();

    useEffect(() => {
        mutate();
    }, []);

    // Check permission and render accordingly
    const renderContent = () => {
        if (!hasPermission(PERMISSIONS.PROFESSIONS_RECOMMENDED)) {
            return (
                <SwiperLockedComponent>
                    <h3 className="pb-2">{t('CTA:upgradeRecommendedProfessions:title')}</h3>
                    <p className="pb-3">{t('CTA:upgradeRecommendedProfessions:description')}</p>
                    <LinkButton text={t('CTA:upgradeRecommendedProfessions:btn')} icon='chevron-right' to={'/vouchers'} />
                </SwiperLockedComponent>
            );
        }

        if (totalCompletion < 100) {
            return (
                <SwiperLockedComponent>
                    <h3 className="pb-2">{t('CTA:profileIncomplete:title')}</h3>
                    <p className="pb-3">{t('CTA:profileIncomplete:description')}</p>
                    <LinkButton text={t('CTA:profileIncomplete:btn')} icon='chevron-right' to={'/profile'} />
                </SwiperLockedComponent>
            );
        }

        return (
            <div>
                <h2>{t('professions:overview:recommendedTitle')}</h2>
                <ProfessionSwiper professions={recommendedProfessions} showIndex={true} />
            </div>
        );
    };

    return (
        <Container fluid className="pt-3 pb-4">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="pb-3 pt-3">
                            {renderContent()}
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default RecommendedProfessions;
