export enum Ambition {
    GrowInCurrentProfession = 1,
    WorkEfficientlyWithTech = 2,
    PrepareForChanges = 3,
    SwitchProfession = 4,
    LearnToBeAttractive = 5,
    PrepareForITCareer = 6,
    ImproveJobMarketChances = 7,
    BuildPortfolio = 8,
    SupportStudyWithITSkills = 9,
    StrongStartInJobMarket = 10,
    SpecializeInIT = 11,
    FindITInternships = 12,
    ParticipateInDigitalSociety = 13,
    LearnDigitalSkills = 14,
    PersonalDevelopment = 15,
    CombineHobbiesWithIT = 16,
    PrepareForReturnToWork = 17,
    Other = 18
}