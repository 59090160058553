import { ProfileContext } from '@/context/ProfileContext';
import { Profile } from '@/profile/model/profile';
import { useContext, useState } from 'react';
import { Container, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../button/Button';
import LanguageSelector from '../language-selector/LanguageSelector';
import './Onboarding.scss';
import OnboardingPitch from './OnboardingPitch';
import OnboardingForm from "@/components/onboarding/OnboardingForm";
import { t } from "i18next";

function OnboardingInfoStep({ onProceed }: { onProceed: () => void }) {
    const { t } = useTranslation();

    return (
        <div className="onboarding-start py-2 px-4">
            <h1 className="title mb-4">{t('onboarding:infoStep:title')}</h1>
            <p>
                {t('onboarding:infoStep:description1')}
            </p>
            <p>
                <strong>{t('onboarding:infoStep:important')}</strong> {t('onboarding:infoStep:description2')}
            </p>
            <p>
                {t('onboarding:infoStep:description3')}
            </p>
            <p className="mt-2">
                <strong>{t('onboarding:infoStep:upcomingPlansTitle')}</strong><br/>
                <div className="mt-2">{t('onboarding:infoStep:upcomingPlans1')}</div>
                <div className="mt-1">{t('onboarding:infoStep:upcomingPlans2')}</div>
            </p>
            <Button className="mt-5" displayType="primary" text={t('onboarding:infoStep:buttonVerder')} onClick={onProceed} />
        </div>
    );
}

type OnboardingProps = {
    isSubmitting?: boolean;
    onConfirm: () => void;
};

function Onboarding({ isSubmitting, onConfirm }: OnboardingProps) {
    const { i18n } = useTranslation();
    const [onboardingStep, setOnboardingStep] = useState<number>(0);
    const [onboardingUsingForm, setOnboardingUsingForm] = useState<boolean>(true);
    const [showBackBtn, setShowBackBtn] = useState<boolean>(false);

    const proceedToNextStep = () => setOnboardingStep(onboardingStep + 1);

    return (
        <Modal className="wihv-modal onboarding-modal" size="xl" show={true} backdrop="static" centered>
            <Modal.Header>
                <img src={`/assets/img/werk-in-het-vooruitzicht-fullcolor.svg`} alt="logo" />
                <div className="language-selection">
                    <img className="language-flag" src={`/assets/img/languages/${i18n.language}.svg`} alt={i18n.language} />
                    <LanguageSelector />
                </div>
            </Modal.Header>

            <Modal.Body as={Container} className="p-2 p-md-4">
                {onboardingStep === 0 && <OnboardingInfoStep onProceed={proceedToNextStep} />}

                {onboardingStep === 1 && (
                    <div className="onboarding-start">
                        <h1 className="title">{t('onboarding:startTitle')}</h1>
                        <p className="description">{t('onboarding:startDescription')}</p>
                        <div className="onboarding-options">
                            <div
                                className={`option ${onboardingUsingForm ? 'option--selected' : ''}`}
                                tabIndex={0}
                                onClick={() => setOnboardingUsingForm(true)}
                            >
                                <i className="icon fas fa-keyboard"></i>
                                <h2>{t('onboarding:formOptionTitle')}</h2>
                                <p>{t('onboarding:formOptionDescription')}</p>
                            </div>
                            {/*<div className={`option ${!onboardingUsingForm ? 'option--selected' : ''}`} tabIndex={0} onClick={() => setOnboardingUsingForm(false)}>*/}
                            {/*    <i className='icon fas fa-video'></i>*/}
                            {/*    <h2>{t('onboarding:videoOptionTitle')}</h2>*/}
                            {/*    <p>{t('onboarding:videoOptionDescription')}</p>*/}
                            {/*</div>*/}
                        </div>
                        <Button displayType="primary" text={t('onboarding:startButtonText')} icon="play" onClick={proceedToNextStep} />
                    </div>
                )}

                {onboardingStep === 2 && (
                    <>
                        {showBackBtn && (
                            <div>
                                <Button displayType="tertiary" text={t('onboarding:backButtonText')} icon="arrow-left" onClick={() => setOnboardingStep(onboardingStep - 1)} />
                            </div>
                        )}
                        {onboardingUsingForm ? <OnboardingForm onboardingUsingForm={onboardingUsingForm} /> : <OnboardingPitch />}
                    </>
                )}
            </Modal.Body>

            <Modal.Footer></Modal.Footer>
        </Modal>
    );
}

export default Onboarding;
