import { Ambition } from '@/profile/model/ambition';
import { WorkStatus } from '@/profile/model/work-status';

/**
 * Dictionary of possible ambitions based on work status.
 */
export const ambitionsByWorkStatus = {
    [WorkStatus.Employed]: [
        Ambition.GrowInCurrentProfession,
        Ambition.WorkEfficientlyWithTech,
        Ambition.PrepareForChanges,
        Ambition.SwitchProfession,
        Ambition.Other
    ],
    [WorkStatus.Unemployed]: [
        Ambition.LearnToBeAttractive,
        Ambition.PrepareForITCareer,
        Ambition.ImproveJobMarketChances,
        Ambition.BuildPortfolio,
        Ambition.Other
    ],
    [WorkStatus.Student]: [
        Ambition.SupportStudyWithITSkills,
        Ambition.StrongStartInJobMarket,
        Ambition.SpecializeInIT,
        Ambition.FindITInternships,
        Ambition.Other
    ],
    [WorkStatus.Other]: [
        Ambition.ParticipateInDigitalSociety,
        Ambition.LearnDigitalSkills,
        Ambition.PersonalDevelopment,
        Ambition.CombineHobbiesWithIT,
        Ambition.PrepareForReturnToWork,
        Ambition.Other
    ]
};