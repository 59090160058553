import FilterComponent from "@/components/filter-component/FilterComponent";
import SearchComponent from "@/components/search/SearchComponent";
import {BreadcrumbsContext} from "@/context/BreadcrumbsContext";
import {AttributeCategory} from "@/general/model/attribute-category";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import {useDocumentTitle} from "@/hooks/useDocumentTitle";
import useProfileAttributes from "@/hooks/useProfileAttributes";
import {t} from "i18next";
import {useContext, useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import './DevelopmentOverview.scss';
import CategoryAttributes from "./components/CategoryAttributes";
import ProfileEvaluation from "./components/profile-evaluation/ProfileEvaluation";
import CTAFullWidth from "@/components/cta/CTAFullWidth";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";

function DevelopmentOverview() {
    useDocumentTitle(t('documentTitles:development'));
    const {setBreadcrumbs} = useContext(BreadcrumbsContext);
    const {currentAccount} = useCurrentAccount();
    const {filteredAttributes, filterOptions, isLoading, updateFilters, updateSearchterm} = useProfileAttributes();
    const {profileCompleted} = useProfileCompletionRequirements();
    const {hasPermission} = useCurrentAccount();

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:development')
        }]);
    }, [setBreadcrumbs]);

    useEffect(() => {
        if (!profileCompleted) {
            document.body.classList.add('noscroll');
        }
        return () => {
            document.body.classList.remove('noscroll');
        };
    }, [profileCompleted]);

    return (
        <div className="development-overview">
            <div className={`${profileCompleted ? '' : 'locked-overview'}`}>
                {!profileCompleted && (
                    <Container fluid className="locked-info">
                        <Container>
                            <CTAFullWidth
                                title={t('CTA:profileIncomplete:title')}
                                description={t('CTA:profileIncomplete:description')}
                                imageSrc="/assets/img/WIHV_3D_Visual_User_Icon_Add.jpg"
                                buttonLink="/profile"
                                buttonText={t('CTA:profileIncomplete:btn')}
                                colSpan={9}
                                colOffset={1}
                            />
                        </Container>
                    </Container>
                )}


                <Container fluid className='introduction py-5'>
                    <Container>
                        <h1 className="title">
                            {t('developmentOverview:title', {name: currentAccount?.name})}
                        </h1>
                        <p className="description">
                            {t('developmentOverview:description')}
                        </p>
                    </Container>
                </Container>

                <Container fluid>
                    <Container>
                        {filteredAttributes && (
                            <>
                                <Row className="my-4">
                                    <Col sm={12}>
                                        <div className='filters d-flex gap-3 flex-column flex-md-row'>
                                            <FilterComponent
                                                options={filterOptions}
                                                onFiltersChange={(value) => updateFilters(value)}
                                                placeholder={t('developmentOverview:filterPlaceholder')}
                                            />
                                            <SearchComponent
                                                placeholder={t('developmentOverview:searchPlaceholder')}
                                                onSearchChange={(value) => updateSearchterm(value)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="categories">
                                    <Col sm={{span: 12, offset: 0}}>
                                        <CategoryAttributes
                                            category={AttributeCategory.Personality}
                                            attributes={filteredAttributes.filter(x => x.category === AttributeCategory.Personality)}
                                        />
                                    </Col>
                                    <Col sm={{span: 12, offset: 0}} className="mt-5">
                                        <CategoryAttributes
                                            category={AttributeCategory.Skills}
                                            attributes={filteredAttributes.filter(x => x.category === AttributeCategory.Skills)}
                                        />
                                    </Col>
                                    <Col sm={{span: 12, offset: 0}} className="mt-5">
                                        <CategoryAttributes
                                            category={AttributeCategory.Potential}
                                            attributes={filteredAttributes.filter(x => x.category === AttributeCategory.Potential)}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Container>
                </Container>

                <Container fluid className='feedback py-5 mt-5'>
                    <Container>
                        <ProfileEvaluation/>
                    </Container>
                </Container>
            </div>
        </div>
    );
}

export default DevelopmentOverview;
