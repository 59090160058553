import Button from "@/components/button/Button";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import {PERMISSIONS} from "@/general/constants";
import {t} from "i18next";
import {ProgressBar} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import DashboardTile from "./DashboardTile";
import './ProfileRequirementCompletionTile.scss';

type ProfileRequirementCompletionCardProps = {
    tileClassName?: string
}

function ProfileRequirementCompletionTile({tileClassName}: ProfileRequirementCompletionCardProps) {

    const {totalCompletion, isLoading} = useProfileCompletionRequirements();
    const {hasPermission} = useCurrentAccount();

    // Check permissions
    const canAccessProfessionGuide = hasPermission(PERMISSIONS.PROFESSIONS_RECOMMENDED);
    const canAccessQuickScan = hasPermission(PERMISSIONS.QUICKSCAN);

    return (
        <DashboardTile tileClassName={`${tileClassName}`}
                       contentClassName="profile-requirement-completion-tile-content">
            <h2 className="title">
                {t('dashboard:profileRequirementCompletion:title')}
            </h2>

            <div className="profile-progress">
                {isLoading
                    ? <Skeleton height={24} style={{borderRadius: 'var(--border-radius-round)'}}/>
                    : <ProgressBar now={totalCompletion} label={`${totalCompletion}%`}/>
                }
            </div>

            <div className="goals">
                <div className="goal">
                    <h3 className="title">{t('dashboard:profileRequirementCompletion:professionGuideTitle')}</h3>
                    <p className="description">
                        {canAccessProfessionGuide
                            ? t('dashboard:profileRequirementCompletion:professionGuideDescription')
                            : t('dashboard:profileRequirementCompletion:noPermissionProfessionGuideDescription')}
                    </p>                    
                    <Button
                    displayType='primary'
                    disabled={totalCompletion !== 100 || !canAccessProfessionGuide}
                    text={t('dashboard:profileRequirementCompletion:professionGuideBtn')}
                    icon={totalCompletion === 100 && canAccessProfessionGuide ? 'arrow-right' : 'lock'}/>
                </div>

                <div className="goal">
                    <h3 className="title">{t('dashboard:profileRequirementCompletion:QuickscanTitle')}</h3>
                    <p className="description">
                        {canAccessQuickScan
                            ? t('dashboard:profileRequirementCompletion:QuickscanDescription')
                            : t('dashboard:profileRequirementCompletion:noPermissionQuickScanDescription')}
                    </p>                    
                    <Button
                    displayType='primary'
                        disabled={totalCompletion !== 100 || !canAccessQuickScan}
                        text={t('dashboard:profileRequirementCompletion:QuickscanBtn')}
                        icon={totalCompletion === 100 && canAccessQuickScan ? 'arrow-right' : 'lock'}/>
                </div>
            </div>
        </DashboardTile>
    );
}

export default ProfileRequirementCompletionTile;
