import LinkButton from '@/components/button/LinkButton';
import { ErrorComponent } from '@/components/error-component/ErrorComponent';
import SkeletonSwiperComponent from '@/components/swiper-component/SkeletonSwiperComponent';
import { BreadcrumbsContext } from '@/context/BreadcrumbsContext';
import { ConfigContext } from '@/context/ConfigContext';
import { MeasurementResult } from '@/measurements/model/measurement-results/measurement-result';
import ProfessionSwiper from '@/professions/components/ProfessionSwiper';
import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { MeasurementDefinition } from '../model/measurement-definitions/measurement-definition';
import MeasurementInfo from './MeasurementInfo';
import './MeasurementResults.scss';
import AttributesResult from './result-types/AttributesResult';

function MeasurementResults() {

    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const configContext = useContext(ConfigContext);
    const { measurementId } = useParams();
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);

    const result: MeasurementResult = location.state?.result;
    const baseUrl = (isAuthenticated ? configContext.configBody.api.baseUrl : configContext.configBody.api.publicBaseUrl)

    const { data: measurement, isLoading: measurementLoading, error: measurementError, mutate } = useSWR<MeasurementDefinition>(measurementId ? baseUrl + configContext.configBody.api.endpoints.measurements.detail.replace('{id}', measurementId) : null, { shouldRetryOnError: false });
    const { data: apiResult, isLoading: resultLoading, error: resultError } = useSWR<MeasurementResult>(!result ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.measurements.results.replace('{id}', measurementId) : null, { shouldRetryOnError: false });
    const [measurementResult, setMeasurementResult] = useState<MeasurementResult>(null);
    const isLoading = measurementLoading || resultLoading;

    useEffect(() => {

        if (result) {
            setMeasurementResult(result);
        }

        if (apiResult) {
            setMeasurementResult(apiResult);
        }

    }, [result, apiResult]);

    useEffect(() => {
        setBreadcrumbs([
            {
                label: t('documentTitles:measurements'),
                path: '/measurements'
            },
            {
                label: measurement?.title,
                path: `/measurements/${measurement?.id}`
            }, 
            {
                label: t('measurements:results:title')
            }
        ]);
    }, [measurement, setBreadcrumbs]);

    if (measurementError || resultError) {
        return <ErrorComponent message={t('measurements:results:resultError')} />;
    }

    return (
        <div className='measurement-results'>
            <Container fluid className="position-relative pt-5 header-container">
                <Container className='result'>
                    <div className='background-img'>
                        <img src={measurement?.imageUrl} alt='' />
                    </div>

                    <h1 className='title'>
                        <i className='icon fas fa-chart-simple me-3'></i>
                        {t('measurements:results:title')}
                    </h1>

                    {measurement
                        ? <h2 className='measurement-title'>{measurement.title}</h2>
                        : <Skeleton width={'40%'} height={48} className='mb-3' baseColor='rgba(255,255,255,0.6)' highlightColor='rgba(255,255,255,0.8)' />
                    }

                    {measurementResult
                        ? <p className='result-summary'>{measurementResult.summary}</p>
                        : <Skeleton height={24} width={'50%'} count={3} baseColor='rgba(255,255,255,0.6)' highlightColor='rgba(255,255,255,0.8)' />
                    }

                    <Col sm={12} md={8} className='pt-4 pb-5 position-relative'>
                        <AttributesResult attributes={measurementResult?.result} />
                    </Col>

                    <Row className='pb-5 gy-4 position-relative'>
                        <Col sm={12}>
                            <LinkButton to={`/development`} text={t('measurements:results:profileBtn')} icon='arrow-right' />
                        </Col>
                        <Col sm={12} className='d-flex gap-4 flex-wrap'>
                            <LinkButton to={`/measurements/${measurement?.id}`} text={t('measurements:results:restartBtn')} icon='rotate-right' displayType='secondary' />
                            <LinkButton to={`/measurements`} text={t('measurements:results:backBtn')} icon='list' displayType='secondary' />
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/*<Container fluid className="py-5">*/}
            {/*    <Container className='recommended-professions'>*/}
            {/*        <h2 className='title'>{t('measurements:results:professionsTitle')}</h2>*/}
            {/*        {resultLoading*/}
            {/*            ? <SkeletonSwiperComponent />*/}
            {/*            : <ProfessionSwiper professions={measurementResult?.professions || []} />*/}
            {/*        }*/}
            {/*    </Container>*/}
            {/*</Container>*/}

            <Container fluid className="py-5">
                <Container>
                    <MeasurementInfo isLoading={isLoading} description={measurement?.description} attributes={measurement?.attributes} />
                </Container>
            </Container>
        </div>
    )
}

export default MeasurementResults