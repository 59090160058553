import SkeletonSwiperComponent from "@/components/swiper-component/SkeletonSwiperComponent";
import SwiperLockedComponent from "@/components/swiper-locked-component/SwiperLockedComponent";
import LinkButton from "@/components/button/LinkButton";
import useRecommendedMeasurements from "@/hooks/useRecommendedMeasurements";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import { PERMISSIONS } from "@/general/constants";
import { t } from "i18next";
import { Col, Container, Row } from "react-bootstrap";
import MeasurementSwiper from "./MeasurementSwiper";

function RecommendedMeasurements() {
    const { recommendedMeasurements, isLoading } = useRecommendedMeasurements();
    const { totalCompletion } = useProfileCompletionRequirements();
    const { hasPermission } = useCurrentAccount();

    const renderContent = () => {
        if (!hasPermission(PERMISSIONS.MEASUREMENTS_RECOMMENDED)) {
            return (
                <SwiperLockedComponent>
                    <h3 className="pb-2">{t('upgrade:recommendedTestsTitle')}</h3>
                    <p className="pb-3">{t('upgrade:recommendedTestsDescription')}</p>
                    <LinkButton text={t('upgrade:recommendedTestsButton')} icon="chevron-right" to="/vouchers" />
                </SwiperLockedComponent>
            );
        }

        if (totalCompletion < 100) {
            return (
                <SwiperLockedComponent>
                    <h3 className="pb-2">{t('CTA:profileIncomplete:title')}</h3>
                    <p className="pb-3">{t('CTA:profileIncomplete:description')}</p>
                    <LinkButton text={t('CTA:profileIncomplete:btn')} icon="chevron-right" to="/profile" />
                </SwiperLockedComponent>
            );
        }

        return (
            <div>
                <h2>{t('measurements:overview:recommendedTitle')}</h2>
                {recommendedMeasurements?.length > 0 ? (
                    <MeasurementSwiper measurements={recommendedMeasurements} />
                ) : (
                    isLoading && <SkeletonSwiperComponent />
                )}
            </div>
        );
    };

    return (
        <Container fluid className="pt-3 pb-4">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="pb-3 pt-3">{renderContent()}</div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default RecommendedMeasurements;
