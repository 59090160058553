import { FC } from "react";
import { AmbitionStepProps } from "@/components/onboarding/AmbitionStepProps";
import { Form } from "react-bootstrap";
import "./AmbitionStep.scss";
import { t } from "i18next";
import { Ambition } from "@/profile/model/ambition";

/**
 * Displays and manages the user's ambition (radio buttons + optional text field).
 */
const AmbitionStep: FC<AmbitionStepProps> = ({
                                                 currentAmbitions,
                                                 selectedAmbition,
                                                 onSelectedAmbitionChange,
                                                 otherAmbition,
                                                 onOtherAmbitionChange,
                                             }) => {
    return (
        <section className="ambition-step">
            <div>
                <h2>{t("ambitionStep:title")}</h2>
            </div>

            <div className="form-container">
                <Form>
                    {currentAmbitions.map((ambition, index) => (
                        <div
                            key={index}
                            className={`radio ${
                                selectedAmbition === ambition ? "selected" : ""
                            }`}
                            onClick={() => onSelectedAmbitionChange(ambition)}
                        >
                            <label>
                                <input
                                    type="radio"
                                    name="ambition"
                                    value={ambition}
                                    checked={selectedAmbition === ambition}
                                    onChange={() => onSelectedAmbitionChange(ambition)}
                                />
                                <span>{t(`ambition:${ambition}`)}</span>
                            </label>
                        </div>
                    ))}

                    {selectedAmbition === Ambition.Other && (
                        <Form.Group className="mt-1">
                            <Form.Control
                                type="text"
                                placeholder={t("ambitionStep:otherPlaceholder")}
                                value={otherAmbition}
                                onChange={(e) => onOtherAmbitionChange(e.target.value)}
                            />
                        </Form.Group>
                    )}
                </Form>
            </div>
        </section>
    );
};

export default AmbitionStep;
