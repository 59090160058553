import { ProfileContext } from '@/context/ProfileContext';
import { Profile } from '@/profile/model/profile';
import { WorkStatus } from '@/profile/model/work-status';
import { Ambition } from '@/profile/model/ambition';

import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { createRef, FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Step } from '../stepper/Stepper';
import StepperComponent from '../stepper/Stepper';
import Button from '../button/Button';

import ProfileStep from './ProfileStep';
import WorkStatusStep from './WorkStatusStep';
import AmbitionStep from './AmbitionStep';

import './OnboardingForm.scss';
import { Form } from 'react-bootstrap';
import {ambitionsByWorkStatus} from "@/profile/model/ambitionsByWorkStatus";

interface OnboardingFormProps {
    onboardingUsingForm: boolean;
}

const OnboardingForm: FC<OnboardingFormProps> = ({ onboardingUsingForm }) => {
    const navigate = useNavigate();
    const profileContext = useContext(ProfileContext);

    // Form reference for profile step
    const formRef = createRef<HTMLFormElement>();
    const [validated, setValidated] = useState(false);

    // Step control
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);

    // Ambition tracking
    const [selectedAmbition, setSelectedAmbition] = useState<Ambition | null>(null);
    const [otherAmbition, setOtherAmbition] = useState<string>('');

    // Steps definition
    const [steps, setSteps] = useState<Step[]>([
        { stepValue: 0, stepLabel: t('onboarding:stepProfile'), completed: false },
        { stepValue: 1, stepLabel: t('onboarding:stepWorkStatus'), completed: false },
        { stepValue: 2, stepLabel: t('onboarding:stepAmbition'), completed: false },
    ]);

    const currentAmbitions = ambitionsByWorkStatus[profileContext.tempChanges?.workStatus] || [];

    /**
     * Utility function: update the tempChanges in ProfileContext
     */
    const updateTempChanges = (propertyName: string, newValue: any) => {
        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            [propertyName]: newValue,
        };
        profileContext.updateChanges(updatedProfile);
    };

    /**
     * Attempts to save the final profile changes to backend, then navigates to /profile
     */
    const saveChanges = () => {
        const ambition =
            selectedAmbition === Ambition.Other
                ? { type: Ambition.Other, other: otherAmbition }
                : { type: selectedAmbition };

        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            isOnboardingComplete: true,
            ambition,
        };

        profileContext
            .saveProfile(updatedProfile)
            .then(() => navigate('/profile'))
            .catch((err: any) => console.error('Failed to save profile:', err));
    };

    /**
     * Whenever steps array changes, check if all steps are completed to enable final submit.
     */
    useEffect(() => {
        setSubmitEnabled(steps.every((step) => step.completed));
    }, [steps]);

    /**
     * Update step completion statuses when relevant data changes.
     */
    useEffect(() => {
        setSteps((prev) =>
            prev.map((step) => {
                if (step.stepValue === 0) {
                    return formRef.current?.checkValidity() || !formRef.current
                        ? { ...step, completed: true }
                        : { ...step, completed: false };
                }

                if (step.stepValue === 1) {
                    return profileContext.tempChanges?.workStatus
                        ? { ...step, completed: true }
                        : { ...step, completed: false };
                }

                if (step.stepValue === 2) {
                    return selectedAmbition
                        ? { ...step, completed: true }
                        : { ...step, completed: false };
                }

                return step;
            })
        );
    }, [profileContext.tempChanges, selectedAmbition, formRef]);

    /**
     * Move to next step if current step conditions are satisfied.
     */
    const nextStep = () => {
        setValidated(true);

        if (currentStepIndex === 0 && formRef.current?.checkValidity()) {
            setSteps((prev) =>
                prev.map((step) =>
                    step.stepValue === currentStepIndex
                        ? { ...step, completed: true }
                        : step
                )
            );
            setCurrentStepIndex(currentStepIndex + 1);
        } else if (
            currentStepIndex === 1 &&
            profileContext.tempChanges?.workStatus
        ) {
            setCurrentStepIndex(currentStepIndex + 1);
        } else if (currentStepIndex === 2 && selectedAmbition) {
            setCurrentStepIndex(currentStepIndex + 1);
        }
    };

    return (
        <div className="onboarding-form">
            <StepperComponent
                steps={steps}
                currentStepIndex={currentStepIndex}
                stepClickEnabled={false}
                onHandleStepClick={() => {}}
            />

            <div className="form-steps">
                <AnimatePresence mode="wait">
                    {currentStepIndex === 0 && (
                        <motion.div
                            key="step1"
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            <ProfileStep
                                formRef={formRef}
                                validated={validated}
                                givenName={profileContext.tempChanges?.givenName ?? ''}
                                surname={profileContext.tempChanges?.surname ?? ''}
                                city={profileContext.tempChanges?.city ?? ''}
                                nationality={profileContext.tempChanges?.nationality ?? ''}
                                onUpdateTempChanges={updateTempChanges}
                            />
                        </motion.div>
                    )}

                    {currentStepIndex === 1 && (
                        <motion.div
                            key="step2"
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            <WorkStatusStep
                                currentWorkStatus={profileContext.tempChanges?.workStatus}
                                onUpdateTempChanges={updateTempChanges}
                            />
                        </motion.div>
                    )}

                    {currentStepIndex === 2 && (
                        <motion.div
                            key="step3"
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            <AmbitionStep
                                currentAmbitions={currentAmbitions}
                                selectedAmbition={selectedAmbition}
                                onSelectedAmbitionChange={setSelectedAmbition}
                                otherAmbition={otherAmbition}
                                onOtherAmbitionChange={setOtherAmbition}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>

            <div className="actions">
                {currentStepIndex > 0 && (
                    <Button
                        text={t('onboarding:previousButton')}
                        displayType="secondary"
                        onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
                        icon="arrow-left"
                    />
                )}

                {currentStepIndex < steps.length - 1 && (
                    <Button
                        text={t('onboarding:nextButton')}
                        displayType="secondary"
                        onClick={nextStep}
                        icon="arrow-right"
                    />
                )}

                {currentStepIndex === steps.length - 1 && (
                    <Button
                        displayType="primary"
                        text={t('onboarding:saveButton')}
                        icon="floppy-disk"
                        disabled={!submitEnabled}
                        onClick={saveChanges}
                    />
                )}
            </div>
        </div>
    );
};

export default OnboardingForm;
