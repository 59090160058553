
import AnchorButton from "@/components/button/AnchorButton";
import Button from "@/components/button/Button";
import FormCard from "@/components/cards/FormCard";
import ConfirmModal from "@/components/confim-modal/ConfirmModal";
import { ConfigContext } from "@/context/ConfigContext";
import { AD_TOKEN_CLAIMS_IDENTITY_PROVIDER, IDENTITY_PROVIDER_LINKEDIN_NAME, IDENTITY_PROVIDER_LINKEDIN_PASSWORD_RESET_URL } from "@/general/constants";
import useAuthenticatedRequest from "@/hooks/useAuthenticatedRequest";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import { useMsal } from "@azure/msal-react";
import { t } from "i18next";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { mutate } from "swr";

function AccountSettings() {

    const { instance } = useMsal();
    const { currentAccount } = useCurrentAccount();
    const authenticatedRequest = useAuthenticatedRequest();
    const configContext = useContext(ConfigContext);
    const identityProvider = currentAccount?.idTokenClaims[AD_TOKEN_CLAIMS_IDENTITY_PROVIDER];

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleDelete = () => {

        setShowConfirmModal(false);
        setIsSubmitting(true);

        authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.profile.detail, 'DELETE')
            .then(() => {
                toast.success(t('settings:toasts:deleteProfileSuccess'));
                mutate(() => true, undefined, false);
                instance.logoutRedirect();
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('settings:toasts:deleteProfileFailed') + ' ' + error.message);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    return (
        <>
            <FormCard title={t('settings:account:title')}>
                <p>{t('settings:account:description')}</p>

                <div className="mt-4 mb-5">
                    <div className="h5 font-bold mb-2">{t('settings:account:deleteProfileTitle')}</div>
                    <p className="mb-3">{t('settings:account:deleteProfileDescription')}</p>
                    <Button text={t('settings:account:deleteProfileBtn')} onClick={() => setShowConfirmModal(true)} displayType="secondary" icon="trash-can" />
                </div>

                <div className="">
                    <div className="h5 font-bold mb-2">{t('settings:account:changePasswordTitle')}</div>
                    <p className="mb-3">
                        {
                            identityProvider === IDENTITY_PROVIDER_LINKEDIN_NAME ?
                                t('settings:account:changePasswordDescriptionLinkedIn') :
                                t('settings:account:changePasswordDescription')
                        }
                    </p>
                    <AnchorButton
                        target="_blank"
                        rel="noopener noreferrer"
                        icon="external-link"
                        displayType="secondary"
                        text={t('settings:account:changePasswordBtn')}
                        href="https://passwordreset.microsoftonline.com/?ru=https%3a%2f%2flogin.microsoftonline.com%2f1bb26ea1-538d-4db1-be7b-72c34a0b5b6d%2freprocess%3fctx%3drQQIARAArVI7bNNQFLWbtLRRaaN26dihU4sb-8Vx6kgRqkhI49rOz47rLJE_L_FLnLxgu0kTRgYYEGJiYEFCQkgVQ8WEmJjbpQyVoANiRBVCiKUdcWFiZ7n3SkfnnHt17noEbNKZNbAFWMZiTCrFpjmKTVk8xXN2iuJNK8naZtqA0PKWYnFm-cuOFn1RfKMeDZq3z-4fkqITBAM_k0iMkDP0TWDZcLgZNhe3UX_Twr1_ANzvIcvDPm4FfzBs7AcOSFxXD_qDdyR5SpLfSPJwamWY4MTcUNspyHRxPyGoewfdLaGdzZ5PLZa2r1nXBXtoAn9NzbWw12sOsB88j3wia4ERwLKHB9ALEPSzcCwoNbWCSkhgRY2ZiJo8MjQ6sHoSV1KkA0lxHR1IgQ4aHX1M0zKQGFHLp3StEsiFhttQ9INGoQJkpQuKaITqeTdf7GAkaYIrdapIBmrIlVi5FnKVCisq7Yne6QalnOzoWr0jdfKphqbSf7m8UL1T5Iq9UK9Qd6SCBEQlH2qHXrnKSB7TrKTotKg0elLH7YVzUldCDyTwbyNrjGkCDhoMlUpu2VSYDEOZMG1SaRDmZNBmyuTs48hCeHof2asDD7eQC0-j5EV0no5kZmdjcWKFWCWuouTL6TDOh-ncs88PmsLrk1clb32eOJ5OOI2NbX7iVspDs5SUa3tgJNOOqgyMAt4d2xtsUQTVcKeyz7PZdIZ5OkP-nCEf3SDez_3XTzifX47F9lHTxZbhQn9pGvYptfbhJnG18P3x2ZOPlyc_di4Wb_XL7d17dLVVam3DNOOZbJ7Dd03V913bcGyxS1eSvIjq9Lg-yh7Fia9x4jc1&mkt=nl&hosted=0&device_platform=Windows+10" />
                </div>
            </FormCard>

            <ConfirmModal
                title={t('settings:account:deleteProfileTitle')}
                description={t('settings:account:confirmDeleteProfileDescription')}
                showModal={showConfirmModal}
                isSubmitting={isSubmitting}
                onConfirm={handleDelete}
                onClose={() => setShowConfirmModal(false)} />
        </>
    )
}

export default AccountSettings