import CookieConsentDisabledPlaceholder from '@/components/cookie-consent-disabled-placeholder/CookieConsentDisabledPlaceholder';
import { ErrorComponent } from '@/components/error-component/ErrorComponent';
import LearningPathContent from '@/components/learning-path/LearningPathContent';
import { LoadingComponent } from '@/components/loading-component/LoadingComponent';
import YoutubeVideoComponent from '@/components/youtube-video-component/YoutubeVideoComponent';
import { BreadcrumbsContext } from '@/context/BreadcrumbsContext';
import { ConfigContext } from '@/context/ConfigContext';
import { CookiePreferencesContext } from '@/context/CookiePreferencesContext';
import { removeHtmlTagsFromString } from '@/general/utilities/RemoveHtmlTagsFromString';
import { LearningPath } from '@/learning/models/learning-path';
import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import FavoriteProfessionToggle from '../../../components/favorite-toggle/FavoriteProfessionToggle';
import { Profession } from '../model/profession';
import './ProfessionDetail.scss';

function ProfessionDetail() {

    const isAuthenticated = useIsAuthenticated();
    const configContext = useContext(ConfigContext);
    const cookiePreferencesContext = useContext(CookiePreferencesContext);
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const { professionId } = useParams();

    const baseUrl = isAuthenticated ? configContext.configBody.api.baseUrl : configContext.configBody.api.publicBaseUrl;
    const { data: profession, isLoading: professionLoading, error: professionError, mutate } = useSWR<Profession>(professionId ? baseUrl + configContext.configBody.api.endpoints.professions.detail.replace('{id}', professionId) : null);
    const { data: learningPaths, isLoading: learningPathsLoading, error: learningPathsError } = useSWR<LearningPath[]>(professionId ? baseUrl + configContext.configBody.api.endpoints.learningPaths.profession.replace('{id}', professionId) : null);

    useEffect(() => {
        setBreadcrumbs([
            {
                label: t('documentTitles:professions'),
                path: '/professions'
            },
            {
                label: profession?.name
            }
        ]);
    }, [setBreadcrumbs, profession]);

    return (
        <>
            {professionLoading &&
                <LoadingComponent message={t('professions:detail:loading')} />
            }

            {professionError &&
                <ErrorComponent message={t('professions:detail:error')} />
            }

            {profession &&
                <div className='profession-detail'>
                    <Container fluid className="header">
                        <Container className='py-5 position-relative'>

                            {/* TODO: Change image to profession.image */}

                            <div className='header-image px-0'>
                                <img src={'/assets/img/professions/WIHV_Professions_Helpdesk.jpg'} alt='' />
                            </div>

                            <Row className='position-relative'>
                                <Col sm={12} md={12} lg={5}>
                                    <h1 className='title'>
                                        <span>{profession.name}</span>
                                        {isAuthenticated &&
                                            <FavoriteProfessionToggle profession={profession} lightTheme={false} />
                                        }
                                    </h1>
                                    <p className='description'>{removeHtmlTagsFromString(profession.description)}</p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    <Container fluid className="py-5">
                        <Container>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Accordion className='accordion'>
                                        <Accordion.Item className='accordion-item' eventKey={'objective'}>
                                            <Accordion.Header className='accordion-header'>
                                                {t('professions:detail:objectiveTitle', { profession: profession.name })}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>{removeHtmlTagsFromString(profession.objectiveDescription)}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <Accordion className='accordion'>
                                        <Accordion.Item className='accordion-item' eventKey={'competences'}>
                                            <Accordion.Header className='accordion-header'>
                                                {t('professions:detail:competencesTitle', { profession: profession.name })}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>{removeHtmlTagsFromString(profession.competencesDescription)}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <Accordion className='accordion'>
                                        <Accordion.Item className='accordion-item' eventKey={'knowledge'}>
                                            <Accordion.Header className='accordion-header'>
                                                {t('professions:detail:knowledgeTitle', { profession: profession.name })}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>{removeHtmlTagsFromString(profession.knowledgeDescription)}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                                <Col sm={12} md={6}>
                                    <Accordion className='accordion'>
                                        <Accordion.Item className='accordion-item' eventKey={'where'}>
                                            <Accordion.Header className='accordion-header'>
                                                {t('professions:detail:whereTitle', { profession: profession.name })}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>{removeHtmlTagsFromString(profession.whereDoesItWork)}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <Accordion className='accordion'>
                                        <Accordion.Item className='accordion-item' eventKey={'skills'}>
                                            <Accordion.Header className='accordion-header'>
                                                {t('professions:detail:skillsTitle', { profession: profession.name })}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>{removeHtmlTagsFromString(profession.skillsDescription)}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                    {profession.videoId &&
                        <Container fluid className="pb-5">
                            <Container>
                                <Row>
                                    <Col sm={12} md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
                                        {cookiePreferencesContext.preferences?.multimedia
                                            ? <YoutubeVideoComponent videoId={profession.videoId} />
                                            : <CookieConsentDisabledPlaceholder />
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    }

                    <Container fluid className="py-5">
                        <Container>
                            <Row>
                                <Col sm={12} md={12} lg={{ span: 10, offset: 1 }} className='learning-paths'>
                                    {learningPaths && learningPaths.length > 0 && (
                                        <>
                                            <h2 className='title'>
                                                {t('professions:detail:learningPathsTitle', { profession: profession.name })}
                                            </h2>
                                            {learningPathsLoading &&
                                                <i className='fas fa-spinner fa-spin' />
                                            }
                                            {learningPathsError &&
                                                <p className='text-danger'>{t('professions:detail:learningPathsError')}</p>
                                            }
                                            <ul className='paths p-0 p-md-3'>
                                                {learningPaths.map((learningPath, index) => (
                                                    <LearningPathContent key={learningPath.id} learningPath={learningPath} opened={index == 0} />
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </Container>

                </div>
            }
        </>
    )
}

export default ProfessionDetail