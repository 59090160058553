import { ErrorComponent } from '@/components/error-component/ErrorComponent';
import UnsavedChangesGuard from '@/components/unsaved-changes-guard/UnsavedChangesGuard';
import { ConfigContext } from '@/context/ConfigContext';
import useAuthenticatedRequest from '@/hooks/useAuthenticatedRequest';
import useProfileAttributes from '@/hooks/useProfileAttributes';
import { EssayMeasurementDefinition } from '@/measurements/model/measurement-definitions/essay-measurement-definition';
import { FormMeasurementDefinition } from '@/measurements/model/measurement-definitions/form-measurement-definition';
import { ImageSelectionMeasurementDefinition } from '@/measurements/model/measurement-definitions/image-selection-measurement-definition';
import { MeasurementDefinition } from '@/measurements/model/measurement-definitions/measurement-definition';
import { MeasurementType } from '@/measurements/model/measurement-types';
import { useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { MeasurementAnswer } from '../model/measurement-answers/measurement-answer';
import MeasurementStart from './MeasurementStart';
import EssayMeasurement from './measurement-types/EssayMeasurement';
import FormMeasurement from './measurement-types/FormMeasurement';
import ImageSelectionMeasurement from './measurement-types/ImageSelectionMeasurement';
import MatrixMeasurement from './measurement-types/matrix-measurement';

function MeasurementContainer() {

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const configContext = useContext(ConfigContext);
    const { measurementId } = useParams();
    const authenticatedRequest = useAuthenticatedRequest();
    const { mutate: muatateProfileAttributes } = useProfileAttributes();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [measurementCompleted, setMeasurementCompleted] = useState<boolean>(false);
    const [measurementStarted, setMeasurementStarted] = useState<boolean>(false);
    const { data: measurement, isLoading, error, mutate } = useSWR<MeasurementDefinition>(measurementId ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.measurements.detail.replace('{id}', measurementId) : null);

    const handleOnSaveMeasurement = async (data: MeasurementAnswer) => {

        setIsSubmitting(true);

        const endpoint = (isAuthenticated
            ? configContext.configBody.api.baseUrl
            : configContext.configBody.api.publicBaseUrl) + configContext.configBody.api.endpoints.measurements.answers.replace('{id}', measurementId);

        authenticatedRequest(endpoint, 'POST', data)
            .then((response) => {
                setMeasurementCompleted(true);
                mutate();
                muatateProfileAttributes();
                toast.success(t('measurements:toasts:saveSuccess'));
                setTimeout(() => {
                    navigate(`/measurements/${measurementId}/results`, { state: { result: response } });
                }, 600);
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('measurements:toasts:saveFailed'));
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const renderMeasurement = (type: MeasurementType) => {
        switch (type) {
            case MeasurementType.Form:
                return <FormMeasurement onSave={handleOnSaveMeasurement} isSubmitting={isSubmitting} measurement={measurement as FormMeasurementDefinition} />
            case MeasurementType.Matrix:
                return <MatrixMeasurement />
            case MeasurementType.Essay:
                return <EssayMeasurement onSave={handleOnSaveMeasurement} isSubmitting={isSubmitting} measurement={measurement as EssayMeasurementDefinition} />
            case MeasurementType.ImageSelection:
                return <ImageSelectionMeasurement onSave={handleOnSaveMeasurement} isSubmitting={isSubmitting} measurement={measurement as ImageSelectionMeasurementDefinition} />
            default:
                return <ErrorComponent message={t('measurements:start:error')} />
        }
    }

    return (
        <>
            {(measurementStarted && measurement) &&
                renderMeasurement(measurement.type)
            }

            {!measurementStarted && !error && !isLoading &&
                <MeasurementStart measurement={measurement} isLoading={isLoading} onStartMeasurement={() => setMeasurementStarted(true)} />
            }

            {error &&
                <ErrorComponent message={t('measurements:start:error')} />
            }

            <UnsavedChangesGuard shouldBlock={measurementStarted && !measurementCompleted} />
        </>
    )
}

export default MeasurementContainer