import LinkButton from "@/components/button/LinkButton";
import SwiperLockedComponent from "@/components/swiper-locked-component/SwiperLockedComponent";
import { PERMISSIONS } from "@/general/constants";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import useRecommendedLearningResources from "@/hooks/useRecommendedLearningResources";
import { t } from "i18next";
import { Col, Container, Row } from "react-bootstrap";
import LearningResourceSwiper from "./LearningResourceSwiper";

function RecommendedLearningResources() {
    const { totalCompletion } = useProfileCompletionRequirements();
    const { recommendedLearningResources, isLoading } = useRecommendedLearningResources();
    const { hasPermission } = useCurrentAccount();

    const renderContent = () => {
        if (!hasPermission(PERMISSIONS.PROFESSIONS_RECOMMENDED)) {
            return (
                <SwiperLockedComponent>
                    <h3 className="pb-2">{t('CTA:upgradeRecommendedLearning:title')}</h3>
                    <p className="pb-3">{t('CTA:upgradeRecommendedLearning:description')}</p>
                    <LinkButton text={t('CTA:upgradeRecommendedLearning:btn')} icon="chevron-right" to="/vouchers" />
                </SwiperLockedComponent>
            );
        }

        if (totalCompletion < 100) {
            return (
                <SwiperLockedComponent>
                    <h3 className="pb-2">{t('CTA:profileIncomplete:title')}</h3>
                    <p className="pb-3">{t('CTA:profileIncomplete:description')}</p>
                    <LinkButton text={t('CTA:profileIncomplete:btn')} icon="chevron-right" to="/profile" />
                </SwiperLockedComponent>
            );
        }

        return (
            <div>
                <h2>{t('learning:overview:recommendedTitle')}</h2>
                <LearningResourceSwiper resources={recommendedLearningResources} />
            </div>
        );
    };

    return (
        <Container fluid className="pt-3 pb-4">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="pb-3 pt-3">{renderContent()}</div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default RecommendedLearningResources;
