import Button from '@/components/button/Button';
import { ProfileContext } from '@/context/ProfileContext';
import { t } from 'i18next';
import { motion } from 'framer-motion';
import { createRef, useContext, useState } from 'react';
import { Card, CloseButton, Col, Form, Modal, Row } from 'react-bootstrap';
import { Ambition } from '@/profile/model/ambition';
import { WorkStatus } from '@/profile/model/work-status';
import './ProfileCard.scss';
import { ambitionsByWorkStatus } from '@/profile/model/ambitionsByWorkStatus';

function ProfileWorkStatusAndAmbition() {
    const profileContext = useContext(ProfileContext);
    const [showEditModal, setShowEditModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const formRef = createRef<HTMLFormElement>();
    const [otherAmbition, setOtherAmbition] = useState(profileContext.profile?.ambition?.other || profileContext.tempChanges?.ambition?.other);
    const ambitionOptions = ambitionsByWorkStatus[profileContext.tempChanges?.workStatus] || [];

    const workStatusOptions = Object.entries(WorkStatus)
        .filter(([key, value]) => typeof value === 'number') // Keep only the enum values
        .map(([key, value]) => ({ label: key, value }));

    const openModal = () => {
        setValidated(false);
        setShowEditModal(true);
    };

    const closeModal = () => {
        setShowEditModal(false);
        profileContext.updateChanges(profileContext.profile);
    };

    const saveChanges = () => {
        setValidated(true);
        if (formRef.current?.checkValidity()) {
            const updatedProfile = {
                ...profileContext.tempChanges,
                ambition: profileContext.tempChanges.ambition?.type === Ambition.Other
                    ? { type: Ambition.Other, other: otherAmbition }
                    : profileContext.tempChanges.ambition,
            };

            profileContext.saveProfile(updatedProfile)
                .then(() => closeModal())
                .catch(() => {});
        }
    };

    const updateTempChanges = (propertyName: string, newValue: any) => {
        let updatedProfile = {
            ...profileContext.tempChanges,
            [propertyName]: newValue,
        };

        // Reset ambition and otherAmbition when workStatus changes
        if (propertyName === 'workStatus') {
            updatedProfile = {
                ...updatedProfile,
                ambition: undefined,
            };
            setOtherAmbition(''); // Clear the "Other" field
        }

        profileContext.updateChanges(updatedProfile);
    };


    return (
        <>
            <Card className="profile-card mb-4">
                <Card.Header>
                    <Card.Title>{t('profile:workStatusAndAmbition:title')}</Card.Title>
                    <motion.button whileTap={{ scale: 0.85 }} onClick={() => openModal()}><i className="fa-solid fa-pen" /></motion.button>
                </Card.Header>
                <Card.Body>
                    <ul>
                        <strong>{t('profile:workStatusAndAmbition:workStatus')}:</strong> {t(`workStatus:${Object.keys(WorkStatus).find(key => WorkStatus[key] === profileContext.profile?.workStatus)}`)}
                        {profileContext.profile?.workStatus && (
                            <>
                                <li><strong>{t('profile:workStatusAndAmbition:ambition')}:</strong> {t(`ambition:${profileContext.profile?.ambition?.type}`)}</li>
                                {profileContext.profile?.ambition?.type === Ambition.Other && (
                                    <li><strong>{t('profile:workStatusAndAmbition:other')}:</strong> {profileContext.profile?.ambition?.other}</li>
                                )}
                            </>
                        )}
                    </ul>
                </Card.Body>
            </Card>

            <Modal className="wihv-modal wihv-modal--profile" size="lg" show={showEditModal} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('profile:workStatusAndAmbition:title')}</Modal.Title>
                    <CloseButton variant="white" onClick={() => closeModal()} />
                </Modal.Header>
                <Modal.Body className="p-3 p-md-5">
                    <Form ref={formRef} validated={validated} noValidate>
                        <Form.Group as={Row}>
                            <Col>
                                <Form.Label>{t('profile:workStatusAndAmbition:workStatus')}</Form.Label>
                                <Form.Select
                                    value={profileContext.tempChanges?.workStatus || ''}
                                    onChange={(e) => updateTempChanges('workStatus', Number(e.target.value))}
                                    required
                                >
                                    <option value="" disabled>{t('profile:workStatusAndAmbition:placeholderWorkStatus')}</option>
                                    {workStatusOptions.map(({ label, value }) => (
                                        <option key={value} value={value}>{t(`workStatus:${label}`)}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        {profileContext.tempChanges?.workStatus && (
                            <>
                                <Form.Group as={Row} className="mt-4">
                                    <Col>
                                        <Form.Label>{t('profile:workStatusAndAmbition:ambition')}</Form.Label>
                                        <Form.Select
                                            value={profileContext.tempChanges?.ambition?.type || ''}
                                            onChange={(e) => updateTempChanges('ambition', { type: Number(e.target.value) })}
                                            required
                                        >
                                            <option value="" disabled>{t('profile:workStatusAndAmbition:placeholderAmbition')}</option>
                                            {ambitionOptions.map((ambition) => (
                                                <option key={ambition} value={ambition}>{t(`ambition:${ambition}`)}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </Form.Group>

                                {profileContext.tempChanges?.ambition?.type === Ambition.Other && (
                                    <Form.Group as={Row} className="mt-4">
                                        <Col>
                                            <Form.Label>{t('profile:workStatusAndAmbition:other')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={otherAmbition}
                                                onChange={(e) => setOtherAmbition(e.target.value)}
                                            />
                                        </Col>
                                    </Form.Group>
                                )}
                            </>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button displayType="secondary" text={t('general:cancel')} onClick={() => closeModal()} />
                    <Button displayType="primary" text={t('general:save')} onClick={() => saveChanges()} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProfileWorkStatusAndAmbition;
