import {FC} from "react";
import {WorkStatusStepProps} from "@/components/onboarding/WorkStatusStepProps";
import {WorkStatus} from "@/profile/model/work-status";
import './WorkStatusStep.scss';
import {t} from "i18next";

/**
 * Displays the user's work status options (Werkend, Werkzoekend, Student, Other).
 */
const WorkStatusStep: FC<WorkStatusStepProps> = ({
                                                     currentWorkStatus,
                                                     onUpdateTempChanges,
                                                 }) => {
    return (
        <section className="workstatus-step">
            <h2>{t('workStatusStep:title')}</h2>
            <div className="option-list">
                <div
                    className={`workstatus ${
                        currentWorkStatus === WorkStatus.Employed ? 'workstatus--selected' : ''
                    }`}
                    onClick={() => onUpdateTempChanges('workStatus', WorkStatus.Employed)}
                >
                    <i className="fas fa-briefcase" />
                    {t('workStatusStep:employed')}
                </div>
                <div
                    className={`workstatus ${
                        currentWorkStatus === WorkStatus.Unemployed
                            ? 'workstatus--selected'
                            : ''
                    }`}
                    onClick={() => onUpdateTempChanges('workStatus', WorkStatus.Unemployed)}
                >
                    <i className="fas fa-search" />
                    {t('workStatusStep:unemployed')}
                </div>
                <div
                    className={`workstatus ${
                        currentWorkStatus === WorkStatus.Student ? 'workstatus--selected' : ''
                    }`}
                    onClick={() => onUpdateTempChanges('workStatus', WorkStatus.Student)}
                >
                    <i className="fas fa-user-graduate" />
                    {t('workStatusStep:student')}
                </div>
                <div
                    className={`workstatus ${
                        currentWorkStatus === WorkStatus.Other ? 'workstatus--selected' : ''
                    }`}
                    onClick={() => onUpdateTempChanges('workStatus', WorkStatus.Other)}
                >
                    <i className="fas fa-ellipsis" />
                    {t('workStatusStep:inactive')}
                </div>
            </div>
        </section>
    );
};
export default WorkStatusStep;