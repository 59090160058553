import useInitialLanguage from '@/hooks/useInitialLanguage';
import AppRouter from '@/layout/app-router';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { t } from 'i18next';
import { CookiePreferencesProvider } from './context/CookiePreferencesProvider';
import { LayoutProvider } from './context/LayoutContextProvider';
import { PlatformSettingsProvider } from './context/PlatformSettingsProvider';
import { reactPlugin } from './general/application-insight';
import { UserbackProvider } from '@userback/react';

interface IAppProps {
    msalInstance: PublicClientApplication;
}

export function App(props: IAppProps) {

    useInitialLanguage();

    return (
        <div>
            <UserbackProvider token="A-NUUSGRo0QjnBjWmKQRGyHefdq">
                <AppInsightsErrorBoundary onError={() => <h1>{t('error_occurred')}</h1>} appInsights={reactPlugin}>
                    <AppInsightsContext.Provider value={reactPlugin}>
                        <MsalProvider instance={props.msalInstance}>
                            <PlatformSettingsProvider>
                                <CookiePreferencesProvider>
                                    <LayoutProvider>
                                        <AppRouter />
                                    </LayoutProvider>
                                </CookiePreferencesProvider>
                            </PlatformSettingsProvider>
                        </MsalProvider>
                    </AppInsightsContext.Provider>
                </AppInsightsErrorBoundary>
            </UserbackProvider>
        </div>
    )
};

export default App;


