import Button from "@/components/button/Button";
import LinkButton from "@/components/button/LinkButton";
import { BreadcrumbsContext } from "@/context/BreadcrumbsContext";
import { useIsAuthenticated } from "@azure/msal-react";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './VouchersOverview.scss';
import RedeemVoucherModal from "./components/RedeemVoucherModal";
import RedeemVoucherPremiumModal from "./components/RedeemVoucherPremiumModal";
import VouchersFrequentlyAskedQuestions from "./components/VouchersFrequentlyAskedQuestions";

const features = [
    { key: "fullTestOffer", basic: false, exclusive: true, premium: true },
    { key: "quickScan", basic: false, exclusive: true, premium: true },
    { key: "personalProfile", basic: true, exclusive: true, premium: true },
    { key: "reflectionTool", basic: false, exclusive: true, premium: true },
    { key: "feedbackZone", basic: false, exclusive: true, premium: true },
    { key: "skillsNavigator", basic: false, exclusive: true, premium: true },
    { key: "professionsGuide", basic: false, exclusive: true, premium: true },
    { key: "trendsDevelopments", basic: true, exclusive: true, premium: true },
    { key: "dashboard", basic: true, exclusive: true, premium: true },
    { key: "recommendations", basic: false, exclusive: true, premium: true },
    { key: "onlineCourses", basic: true, exclusive: true, premium: true },
    { key: "learningTrajectories", basic: true, exclusive: true, premium: true },
    { key: "trainingOverview", basic: true, exclusive: true, premium: true },
    { key: "traineeshipGuide", basic: true, exclusive: true, premium: true },
    { key: "badgesCertificates", basic: true, exclusive: true, premium: true },
    { key: "externalTrainingDiscount", basic: false, exclusive: true, premium: true },
    { key: "cvGenerator", basic: false, exclusive: true, premium: true },
    { key: "skillsPassport", basic: false, exclusive: true, premium: true },
    { key: "employerNetwork", basic: true, exclusive: true, premium: true },
];

function VouchersOverview() {
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const [showRedeemVoucherModal, setShowRedeemVoucherModal] = useState(false);
    const [showRedeemVoucherPremiumModal, setShowRedeemVoucherPremiumModal] = useState(false);
    const [showAllFeatures, setShowAllFeatures] = useState(false); // State to toggle features display
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        setBreadcrumbs([{ label: t('documentTitles:vouchers') }]);
    }, [setBreadcrumbs]);

    const planHasFeature = (feature, plan) => {
        if (plan === 'basic') return feature.basic;
        if (plan === 'exclusive') return feature.exclusive;
        if (plan === 'premium') return feature.premium;
        return false;
    };

    const renderFeatures = (plan) => {
        const filteredFeatures = showAllFeatures ? features : features.slice(0, 7); // Show all or first 7
        return filteredFeatures.map((feature, i) => {
            const included = planHasFeature(feature, plan);
            return (
                <li
                    key={`${plan}-feature-${i}`}
                    className={`feature ${included ? '' : 'feature--disabled'}`}
                >
                    {included ? (
                        <i className="icon fas fa-check"></i>
                    ) : (
                        <i className="icon fas fa-times"></i>
                    )}
                    {t(`vouchers:features.${feature.key}`)}
                </li>
            );
        });
    };

    return (
        <div className="vouchers-overview">
            <Container fluid>
                <Container>
                    <Row>
                        <Col sm={12} className="py-5">
                            <div className="hero">
                                <h1 className="title">{t("vouchers:hero.title")}</h1>
                                <p className="subtitle">{t("vouchers:hero.subtitle")}</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="pb-5">
                        <Col sm={12} className="p-0">
                            <section className="plans">
                                <div className="plan-comparison">
                                    {/* Basic Plan */}
                                    <div className="plan plan--start">
                                        <div className="header">
                                            <h2>{t("vouchers:plans.basic.title")}</h2>
                                            <div className="description">
                                                {t("vouchers:plans.basic.description")}
                                            </div>
                                        </div>
                                        <div className="price">
                                            <span>{t("vouchers:plans.basic.price")}</span>
                                            <span
                                                className="billing-cycle">{t("vouchers:plans.basic.billingCycle")}</span>
                                        </div>
                                        <div className="feature-list">
                                            <ul>
                                                {renderFeatures('basic')}
                                            </ul>
                                            <div className="show-more-container mt-1">
                                                {!showAllFeatures && (
                                                    <a
                                                        href="#"
                                                        className="show-more-link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowAllFeatures(true);
                                                        }}
                                                    >
                                                        {t("vouchers:plans.showMore")}
                                                    </a>
                                                )}
                                                {showAllFeatures && (
                                                    <a
                                                        href="#"
                                                        className="show-more-link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowAllFeatures(false);
                                                        }}
                                                    >
                                                        {t("vouchers:plans.showLess")}
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                        <div className="owned-status">
                                            <i className="icon fas fa-check me-2"/>
                                            <span>{t("vouchers:overview.alreadyOwned")}</span>
                                        </div>
                                    </div>

                                    {/* Exclusive Plan */}
                                    <div className="plan plan--plus">
                                        <div className="header">
                                            <h2>{t("vouchers:plans.exclusive.title")}</h2>
                                            <div className="description">
                                                {t("vouchers:plans.exclusive.description")}
                                            </div>
                                        </div>
                                        <div className="price">
                                            <span>{t("vouchers:plans.exclusive.price")}</span>
                                            <span
                                                className="billing-cycle">{t("vouchers:plans.exclusive.billingCycle")}</span>
                                        </div>
                                        <div className="feature-list">
                                            <ul>
                                                {renderFeatures('exclusive')}
                                            </ul>
                                            <div className="show-more-container mt-1">
                                                {!showAllFeatures && (
                                                    <a
                                                        href="#"
                                                        className="show-more-link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowAllFeatures(true);
                                                        }}
                                                    >
                                                        {t("vouchers:plans.showMore")}
                                                    </a>
                                                )}
                                                {showAllFeatures && (
                                                    <a
                                                        href="#"
                                                        className="show-more-link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowAllFeatures(false);
                                                        }}
                                                    >
                                                        {t("vouchers:plans.showLess")}
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                        <Button
                                            text={t("vouchers:plans.exclusive.buttonApply")}
                                            icon="bag-shopping"
                                            displayType="secondary"
                                            onClick={() =>
                                                window.open("https://werkinhetvooruitzicht.nl/voucher-aanvragen/", "_blank")
                                            }
                                        />
                                        <Button
                                            text={t("vouchers:plans.exclusive.buttonRedeem")}
                                            icon="key"
                                            onClick={() => setShowRedeemVoucherModal(true)}
                                        />
                                    </div>

                                    {/* Premium Plan */}
                                    <div className="plan plan--premium">
                                        <div className="header">
                                            <h2>{t("vouchers:plans.premium.title")}</h2>
                                            <div className="description">
                                                {t("vouchers:plans.premium.description")}
                                            </div>
                                        </div>
                                        <div className="price">
                                            <span>{t("vouchers:plans.premium.price")}</span>
                                            <span
                                                className="billing-cycle">{t("vouchers:plans.premium.billingCycle")}</span>
                                        </div>
                                        <div className="feature-list">
                                            <ul>
                                                {renderFeatures('premium')}
                                            </ul>
                                            <div className="show-more-container mt-1">
                                                {!showAllFeatures && (
                                                    <a
                                                        href="#"
                                                        className="show-more-link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowAllFeatures(true);
                                                        }}
                                                    >
                                                        {t("vouchers:plans.showMore")}
                                                    </a>
                                                )}
                                                {showAllFeatures && (
                                                    <a
                                                        href="#"
                                                        className="show-more-link"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowAllFeatures(false);
                                                        }}
                                                    >
                                                        {t("vouchers:plans.showLess")}
                                                    </a>
                                                )}
                                            </div>
                                        </div>

                                        <Button
                                            text={t("vouchers:plans.premium.buttonApply")}
                                            icon="bag-shopping"
                                            displayType="secondary"
                                            onClick={() =>
                                                window.open("https://werkinhetvooruitzicht.nl/ga-voor-premium/", "_self")
                                            }
                                        />
                                        <Button
                                            text={t("vouchers:plans.premium.buttonRedeem")}
                                            icon="key"
                                            onClick={() => setShowRedeemVoucherPremiumModal(true)}
                                        />
                                    </div>
                                </div>
                            </section>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="faq py-5">
                <Container>
                    <VouchersFrequentlyAskedQuestions/>
                </Container>
            </Container>

            <RedeemVoucherModal
                showModal={showRedeemVoucherModal}
                onSuccess={() => {
                }}
                onClose={() => setShowRedeemVoucherModal(false)}
            />
            
            <RedeemVoucherPremiumModal
                showModal={showRedeemVoucherPremiumModal}
                onSuccess={() => {
                }}
                onClose={() => setShowRedeemVoucherPremiumModal(false)}
            />
        </div>
    );
}

export default VouchersOverview;