import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import './ADComponent.scss';
import {useMsal} from "@azure/msal-react";

export function ADErrorComponent({ error }) {
    const { t } = useTranslation();
    const { instance } = useMsal();

    // Logout function
    const logout = () => {
        instance.logoutRedirect(); // Ensure `instance` is defined or imported correctly
    };

    return (
        <div className='ad-info'>
            <div className='ad-info__bg-img'>
                <img src={'/assets/img/WIHV_3D_Visual_Blauw.jpg'} style={{ objectPosition: 'center center' }} alt='' />
            </div>

            <div className="ad-info__content">
                <Link to='/' className="mb-5">
                    <img src={`/assets/img/werk-in-het-vooruitzicht-fullcolor.svg`} alt='' />
                </Link>
                <div className="h3 font-bold pb-4">{t('activeDirectory:error')}</div>
                <p className="pb-4">{error ? error.errorCode : t('activeDirectory:unknownError')}</p>

                {/* if user is authenticated, show logout button if not redirect to home */}
                {instance.getActiveAccount() ? (
                    <button onClick={logout} className='btn btn-secondary'>{t('activeDirectory:logout')}</button>
                ) : (
                    // Show button for change microsoft account
                    <>
                        <button onClick={() => instance.logoutRedirect()}
                                className='mb-3 btn btn-secondary'>{t('Uitloggen')}</button>
                        <button onClick={() => window.location.href = 'https://werkinhetvooruitzicht.nl/'}
                                className='btn btn-secondary'>Terug naar werkinhetvooruitzicht.nl
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}